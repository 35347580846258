import React, { useRef } from 'react';
import styled from 'styled-components';
import {
  Title,
  TitleRow,
  SubtitleRow,
  Section,
  Wrapper,
} from './marketing.styles';
import Slider from 'react-slick';
import CreatorSectionCard from './CreatorSectionCard';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ChevronRight, ChevronLeft } from 'react-feather';
import Typography, { TypeVariant } from 'shared/Typography';

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`;

const Column = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`;

const IconWrap = styled.div`
  position: absolute;
  top: 33%;
  height: 100%;

  @media (max-width: 1476px) {
    top: 31%;
  }

  @media (max-width: 1200px) {
    top: 28%;
  }

  @media (max-width: 992px) {
    top: 30%;
  }

  @media (max-width: 762px) {
    top: 35%;
  }
`;

const IconWrapLeft = styled(IconWrap)`
  left: -44px;

  @media (max-width: 1025px) {
    left: -20px;
  }

  @media (max-width: 650px) {
    left: -8px;
  }
`;

const IconWrapRight = styled(IconWrap)`
  right: -44px;

  @media (max-width: 1025px) {
    right: -20px;
  }

  @media (max-width: 650px) {
    right: -8px;
  }
`;

const IconHitBox = styled.div`
  padding: 16px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  @media (max-width: 650px) {
    padding: 8px;
  }
`;

const creators = [
  {
    hero: 'marketing/Tara_cnatek.png',
    spinner: 'marketing/Tara_Spinner_gnyaur.png',
    name: 'Tara Frost',
    description: 'Creator of The Coast Sweat',
    page: 'coastsweat',
  },
  {
    hero: 'marketing/Nicole_vc5qoc.png',
    spinner: 'marketing/Nicole_Spinner_mtbdtk.png',
    name: 'Nicole Monroe',
    description: 'Former D1 Sprinter and Army Veteran',
    page: 'raw',
  },
  {
    hero: 'marketing/Josh_Updated_pg90ab.jpg',
    spinner: 'marketing/JoshGoldstein_nf6u2k.png',
    name: 'Josh Goldstein',
    description: 'Founder of JG Fitness',
    page: 'jgfitness',
  },
  {
    hero: 'marketing/BodByTy_eb20sz.png',
    spinner: 'marketing/BodByTy_Spinner_q2marm.png',
    name: 'Ty Smith',
    description: 'Former D1 Athlete',
    page: 'bodiedbyty',
  },
  {
    hero: 'marketing/JJ_udcrms.jpg',
    spinner: 'marketing/JJ_2_oahhes.png',
    name: 'JJ Dancer',
    description: 'The "It Girl" of Hip-Hop Dance Cardio Workouts',
    page: 'banginbody',
  },
  {
    hero: 'marketing/Cinco_Christian_Updated_b2hwsv.jpg',
    spinner: 'marketing/Cinco_Christian_ilbjvx.png',
    name: 'Cinco & Christian',
    description: 'Creators of The Tough Fitness',
    page: 'cinco',
  },
  {
    hero: 'marketing/Gee_Updated_m1tr6k.jpg',
    spinner: 'marketing/GeeGee_m7euto.png',
    name: 'GeeGee Flawless',
    description: 'Creator of Gee Fit',
    page: 'geegee',
  },
  {
    hero: 'marketing/Rilde_ibju2a.png',
    spinner: 'marketing/Rilde_Spinner_avemqh.png',
    name: 'Rilde Leon',
    description: 'Miami Fitness Coach',
    page: 'realdfitness',
  },
  {
    hero: 'marketing/Samantha_ktvq3s.png',
    spinner: 'marketing/Samantha_Spinner_qvnlgz.png',
    name: 'Samantha Ortiz',
    description: 'Rated #1 NYC Trainer',
    page: 'somanti',
  },
  {
    hero: 'marketing/Bree.jpg_odsdps.jpg',
    spinner: 'marketing/Bree_1_ozn6wj.png',
    name: 'Bree Mitchell',
    description: 'Creator of Fun, Innovative and Effective Fitness',
    page: 'bodbybree',
  },
  {
    hero: 'marketing/Jaime_tr5thl.png',
    spinner: 'marketing/Jaime_Spinner_lgrjvj.png',
    name: 'Jaime McFaden',
    description: 'Self Care Specialist',
    page: 'jaimemcfaden',
  },
  {
    hero: 'marketing/Kiara_Updated_khrid2.jpg',
    spinner: 'marketing/Kiara_am4ovd.png',
    name: 'Kiara Phillips',
    description: 'Competitive Body Builder and Former D1 Track & Field',
    page: 'kiarafit',
  },
  {
    hero: 'marketing/Karol_yies2a.jpg',
    spinner: 'marketing/Karol_1_o3jdeq.png',
    name: 'Karol Wothyla',
    description: 'Founder of The Blueprint PTS',
    page: 'karol',
  },
];

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  autoplay: true,
  arrows: false,
  swipeToSlide: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 767,
      settings: { slidesToShow: 1 },
    },
    {
      breakpoint: 992,
      settings: { slidesToShow: 2 },
    },
  ],
};

interface Props {
  lightTheme?: boolean;
}

const CreatorSection: React.FC<Props> = ({ lightTheme = false }) => {
  const sliderRef = useRef<Slider>(null);

  const handlePrev = () => {
    if (sliderRef?.current) {
      sliderRef?.current?.slickPrev();
    }
  };

  const handleNext = () => {
    if (sliderRef?.current) {
      sliderRef?.current?.slickNext();
    }
  };

  return (
    <Section
      style={{
        backgroundColor: lightTheme
          ? 'var(--color-white)'
          : 'var(--color-black)',
      }}
    >
      <Wrapper
        style={{
          backgroundColor: lightTheme
            ? 'var(--color-white)'
            : 'var(--color-black)',
        }}
      >
        <Row>
          <Title>
            <TitleRow>
              <Typography
                variant={TypeVariant.header02}
                as="h2"
                phone={TypeVariant.subtitle01}
                textAlign="center"
                color={
                  lightTheme ? 'var(--color-black-500)' : 'var(--color-white)'
                }
              >
                Featured Creators
              </Typography>
            </TitleRow>
            <SubtitleRow>
              <Typography
                variant={TypeVariant.bodyLarge01}
                phone={TypeVariant.body01}
                as="p"
                textAlign="center"
                color={
                  lightTheme ? 'var(--color-black-500)' : 'var(--color-white)'
                }
              >
                Find a program today
              </Typography>
            </SubtitleRow>
          </Title>

          <Column>
            <IconWrapLeft>
              <IconHitBox onClick={handlePrev}>
                <ChevronLeft
                  color={
                    lightTheme ? 'var(--color-black-500)' : 'var(--color-white)'
                  }
                />
              </IconHitBox>
            </IconWrapLeft>
            <Slider ref={sliderRef} {...settings}>
              {creators.map(({ hero, spinner, name, description, page }, i) => (
                <CreatorSectionCard
                  key={i}
                  hero={hero}
                  spinner={spinner}
                  name={name}
                  description={description}
                  page={page}
                />
              ))}
            </Slider>
            <IconWrapRight>
              <IconHitBox onClick={handleNext}>
                <ChevronRight
                  color={
                    lightTheme ? 'var(--color-black-500)' : 'var(--color-white)'
                  }
                />
              </IconHitBox>
            </IconWrapRight>
          </Column>
        </Row>
      </Wrapper>
    </Section>
  );
};

export default CreatorSection;
